import { Sequence } from "@langue-de-chat-llc/enigmastudio-algorithm";
import { QueryTypeCategory } from "../../QueryTypeChip";

export const sequenceTypes: {
  [key: string]: {
    name: string;
    description?: string;
    option?: any;
    defaultQuery: Sequence;
    singleDirection?: boolean;
    category: QueryTypeCategory;
  };
} = {
  answerKeySearch: {
    name: "解き直しアンサーキー検索",
    description:
      "指定の文字列を同じ位置の文字列に置き換えた時に、辞書に含まれる単語のみ出力します",
    defaultQuery: {
      type: "sequence",
      sequenceType: "answerKeySearch",
      processes: [
        {
          type: "regexReplace",
          pattern: "",
          replacement: "pair($1,,)",
          matchOnly: false,
        },
        {
          type: "dictionaryMatch",
          dictionary: "",
          anagramMatch: false,
        },
      ],
    },
    category: "tool",
  },
  rotateKeySearch: {
    name: "単語移動検索",
    description:
      "単語を配列上で移動させて検索させた時に辞書に含まれる単語のみ出力します",
    defaultQuery: {
      type: "sequence",
      sequenceType: "rotateKeySearch",
      processes: [
        {
          type: "regexReplace",
          pattern: "",
          replacement: "rotate($1,,)",
          matchOnly: false,
        },
        {
          type: "dictionaryMatch",
          dictionary: "",
          anagramMatch: false,
        },
      ],
    },
    category: "tool",
  },
  dakuToSei: {
    name: "濁音→清音",
    description: "濁音を清音に変換します",
    defaultQuery: {
      type: "sequence",
      sequenceType: "dakuToSei",
      processes: [
        {
          type: "regexReplace",
          pattern: "",
          replacement: "pair($1,d&h,d2s&h2s,a)",
          matchOnly: false,
        },
      ],
    },
    category: "transform",
  },
  smallToLarge: {
    name: "小書き文字を大きく",
    description: "ぁ-ぉゃゅょゎを大きくします",
    defaultQuery: {
      type: "sequence",
      sequenceType: "smallToLarge",
      processes: [
        {
          type: "regexReplace",
          pattern: "",
          replacement: "pair($1,k,k2s,a)",
          matchOnly: false,
        },
      ],
    },
    category: "transform",
  },
  kanaToRoma: {
    name: "かな→ローマ字",
    description: "ローマ字に変換します",
    defaultQuery: {
      type: "sequence",
      sequenceType: "kanaToRoma",
      processes: [
        {
          type: "regexReplace",
          pattern: "",
          replacement: "roma($1)",
          matchOnly: false,
        },
      ],
    },
    category: "transform",
  },
  romaToKana: {
    name: "ローマ字→かな",
    description: "かなに変換します",
    defaultQuery: {
      type: "sequence",
      sequenceType: "romaToKana",
      processes: [
        {
          type: "regexReplace",
          pattern: "",
          replacement: "kana($1)",
          matchOnly: false,
        },
      ],
    },
    category: "transform",
  },
  reverse: {
    name: "文字列反転",
    description: "文字列の前後を反転します",
    defaultQuery: {
      type: "sequence",
      sequenceType: "reverse",
      processes: [
        {
          type: "regexReplace",
          pattern: "",
          replacement: "reverse($1)",
          matchOnly: false,
        },
      ],
    },
    category: "transform",
  },
  sort: {
    name: "文字列ソート",
    description: "文字列をソートします",
    defaultQuery: {
      type: "sequence",
      sequenceType: "sort",
      processes: [
        {
          type: "regexReplace",
          pattern: "",
          replacement: "sort($1)",
          matchOnly: false,
        },
      ],
    },
    category: "transform",
  },
  variableParallelTransform: {
    name: "変数展開並列変換",
    description: "変数を変化させつつ複数の正規表現置換変換を行います。",
    defaultQuery: {
      type: "sequence",
      sequenceType: "variableParallelTransform",
      option: {},
      processes: [
        {
          type: "parallelTransform",
          processes: [],
        },
      ],
    },
    singleDirection: true,
    category: "transform",
  },
  simpleSearch: {
    name: "簡易検索",
    description: "ビジュアルアイコンを使って簡易的な単語検索ができます。",
    defaultQuery: {
      type: "sequence",
      sequenceType: "simpleSearch",
      processes: [
        {
          type: "regex",
          pattern: "",
        },
      ],
    },
    singleDirection: true,
    category: "filter",
  },
};
