import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useContext } from "react";
import { EnigmaStudioContext } from "../context/EnigmaStudioContext";
import { DictionaryKey, dictionaries } from "@langue-de-chat-llc/enigmastudio-algorithm";

export const DictionarySelector = ({
  labelName,
  dictionary,
  setDictionary,
  isDemo,
  onBlur,
}: {
  labelName: string;
  dictionary: string;
  setDictionary: (value: string) => void;
  isDemo: boolean;
  onBlur?: () => void;
}) => {
  const ctx = useContext(EnigmaStudioContext);
  return (
    <FormControl variant="outlined">
      <InputLabel id="select-dictionary-label">{labelName}</InputLabel>
      <Select
        labelId="select-dictionary-label"
        value={dictionary || ""}
        onChange={(e) => setDictionary(e.target.value)}
        onBlur={onBlur}
        sx={{
          ml: 1,
          width: "200px",
        }}
        disabled={isDemo}
        error={!ctx.enabledDictionary.includes(dictionary as DictionaryKey)}
      >
        {ctx.enabledDictionary
          .sort((a, b) => dictionaries[a].index - dictionaries[b].index)
          .map((key) => (
            <MenuItem value={key} key={key}>
              {dictionaries[key as DictionaryKey].name}
            </MenuItem>
          ))}
        {ctx.enabledDictionary.length === 0 && (
          <MenuItem value="" key="">
            辞書を有効化してください
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};
