export const numCrossScore = {
    あ: 2,
    い: 1,
    う: 1,
    え: 2,
    お: 2,
    か: 1,
    き: 1,
    く: 1,
    け: 2,
    こ: 2,
    さ: 2,
    し: 1,
    す: 2,
    せ: 2,
    そ: 2,
    た: 2,
    ち: 2,
    つ: 1,
    て: 2,
    と: 2,
    な: 2,
    に: 2,
    ぬ: 5,
    ね: 2,
    の: 2,
    は: 2,
    ひ: 2,
    ふ: 2,
    へ: 5,
    ほ: 2,
    ま: 2,
    み: 2,
    む: 2,
    め: 2,
    も: 2,
    や: 2,
    ゆ: 2,
    よ: 2,
    ら: 2,
    り: 2,
    る: 2,
    れ: 2,
    ろ: 2,
    わ: 2,
    を: 0,
    ん: 1,
    が: 3,
    ぎ: 3,
    ぐ: 3,
    げ: 3,
    ご: 3,
    ざ: 3,
    じ: 3,
    ず: 3,
    ぜ: 4,
    ぞ: 4,
    だ: 3,
    ぢ: 5,
    づ: 5,
    で: 4,
    ど: 3,
    ば: 3,
    び: 3,
    ぶ: 3,
    べ: 4,
    ぼ: 3,
    ぱ: 4,
    ぴ: 4,
    ぷ: 4,
    ぺ: 4,
    ぽ: 4,
    っ: 1,
    ゃ: 2,
    ゅ: 2,
    ょ: 2,
    ゎ: 2,
    ゐ: 0,
    ゑ: 0,
    ゔ: 0,
    ゕ: 1,
    ゖ: 2,
    ー: 2,
};
