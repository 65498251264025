import { splitGrapheme } from "../wordGenerator/splitGrapheme";
import { dataSource } from "./dictionaryWorker";
const kana = "あいうえおかきくけこさしすせそたちつてとなにぬねのはひふへほまみむめもやゆよらりるれろわをんがぎぐげござじずぜぞだぢづでどばびぶべぼぱぴぷぺぽゔーゃゅょっぁぃぅぇぉabcdefghijiklmnopqrstuvwxyz";
const sortedSource = {};
const createSortedTable = (dictionary) => {
    const words = dataSource[dictionary];
    if (!words) {
        return {};
    }
    if (sortedSource[dictionary]) {
        return sortedSource[dictionary];
    }
    const table = {};
    for (const word of words) {
        const key = splitGrapheme(word).sort().join("");
        table[key] = sortedSource[dictionary]?.[key] ?? [];
        table[key].push(word);
    }
    return (sortedSource[dictionary] = table);
};
export const searchChimata = (allQuery, addCharNum, dictionary) => {
    const sortedInput = allQuery.split("").sort().join("");
    const uniqChars = [...new Set(sortedInput.split(""))];
    const sortedTable = createSortedTable(dictionary) ?? {};
    if (addCharNum === 0) {
        const ret = sortedTable[sortedInput];
        return ret ?? [];
    }
    else if (addCharNum === -1) {
        let ret = [];
        uniqChars.forEach((c) => {
            const index = sortedInput.indexOf(c);
            const key = sortedInput.substring(0, index) + sortedInput.substring(index + 1);
            if (sortedTable[key] !== undefined) {
                ret = ret.concat(sortedTable[key]);
            }
        });
        return ret;
    }
    else if (addCharNum === -2) {
        let ret = [];
        uniqChars.forEach((c) => {
            const index = sortedInput.indexOf(c);
            const key = sortedInput.substring(0, index) + sortedInput.substring(index + 1);
            [...new Set(key.split(""))].forEach((c2) => {
                const index2 = key.indexOf(c2);
                const key2 = key.substring(0, index2) + key.substring(index2 + 1);
                if (sortedTable[key2] !== undefined) {
                    ret = ret.concat(sortedTable[key2]);
                }
            });
        });
        return Array.from(new Set(ret)).sort();
    }
    else if (addCharNum === 1) {
        let ret = [];
        kana.split("").forEach((c) => {
            const key = (sortedInput + c).split("").sort().join("");
            if (sortedTable[key] !== undefined) {
                console.log(sortedTable[key]);
                ret = ret.concat(sortedTable[key]);
            }
        });
        return ret;
    }
    else if (addCharNum === 2) {
        let ret = [];
        kana.split("").forEach((c1) => {
            kana.split("").forEach((c2) => {
                const key = (sortedInput + c1 + c2).split("").sort().join("");
                if (sortedTable[key] !== undefined) {
                    ret = ret.concat(sortedTable[key]);
                }
            });
        });
        return Array.from(new Set(ret)).sort();
    }
    else if (addCharNum >= 3) {
        let ret = [];
        const sortedList = Object.keys(sortedTable);
        sortedList.forEach((key) => {
            if (key.length !== sortedInput.length + addCharNum) {
                return;
            }
            const replacedKey = splitGrapheme(sortedInput).reduce((acc, c) => {
                return acc.replace(c, "");
            }, key);
            if (replacedKey.length !== addCharNum) {
                return;
            }
            ret = ret.concat(sortedTable[key]);
        });
        return Array.from(new Set(ret)).sort();
    }
    return [];
};
