import { timeout } from "../lib/timeout";
import { workerStatus } from "../worker";
let beforeTime = 0;
class GeneralSearchProgress {
    _progress;
    constructor() {
        this._progress = {
            startTime: 0,
            currentTime: 0,
            queryProgress: [],
        };
    }
    get() {
        return this._progress;
    }
    set setNowTime(nowTime) {
        this._progress.currentTime = nowTime;
    }
    set(progress) {
        this._progress = progress;
    }
}
export const progress = new GeneralSearchProgress();
export const sendProgress = async (self, forced) => {
    progress.setNowTime = Date.now();
    if (forced || beforeTime + 50 < progress.get().currentTime) {
        await timeout(0);
        self.postMessage({
            progress: progress.get(),
            abort: workerStatus.abort,
        });
        beforeTime = progress.get().currentTime;
    }
    return workerStatus.abort;
};
