import { splitGrapheme } from "../../../wordGenerator/splitGrapheme";
import { workerStatus } from "../../worker";
import { createDictionaryTable, createSortedTable } from "../table/dictionaryTable";
import { progress } from "../generalSearchProgress";
import { regexReplace } from "../regexReplace";
export const regexReplaceSearch = async ({ query, input, words, listDefinitionsTable, result, workerContext, }) => {
    progress.get().queryProgress[progress.get().queryProgress.length - 1].totalWordCount = 0;
    if (query.type === "regexReplaceSearch") {
        query.matchOnly = true;
    }
    result = await regexReplace(workerContext, query, listDefinitionsTable, result);
    result.forEach((e) => {
        e.trace.push(e.currentWord);
    });
    if (query.type === "regexReplaceSearch") {
        if (!query.anagramMatch) {
            const dictKey = query.dictionary ||
                (input.inputDictionary !== "free" ? input.inputDictionary : "");
            if (!dictKey) {
                return [];
            }
            const dictKeyArr = Array.isArray(dictKey) ? dictKey : [dictKey];
            const dict = createDictionaryTable(dictKeyArr);
            if (!dict) {
                return [];
            }
            {
                const list = [];
                for (const e of result) {
                    if (workerStatus.abort) {
                        break;
                    }
                    if (dict[e.currentWord]) {
                        list.push(e);
                    }
                }
                result = list;
            }
        }
        else {
            // anagram match
            // アナグラムして辞書にある単語に変換します。
            const dictKey = query.dictionary ||
                (input.inputDictionary !== "free" ? input.inputDictionary : "");
            if (!dictKey) {
                return [];
            }
            const dictKeyArr = Array.isArray(dictKey) ? dictKey : [dictKey];
            const dict = createSortedTable(dictKeyArr);
            if (!dict) {
                return [];
            }
            {
                const list = [];
                for (const e of result) {
                    if (workerStatus.abort) {
                        break;
                    }
                    const key = splitGrapheme(e.currentWord).sort().join("");
                    const ret = (dict[key] ?? []).map((e2) => {
                        const newe = JSON.parse(JSON.stringify(e));
                        newe.currentWord = e2;
                        return newe;
                    });
                    list.push(ret);
                }
                result = list.flat();
                result.forEach((e) => {
                    e.trace.push(e.currentWord);
                });
            }
        }
    }
    return result;
};
