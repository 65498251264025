import {
  Box,
  Button,
  Chip,
  Grid,
  TextField,
  ToggleButton,
  Typography,
} from "@mui/material";
import React, { FC, useCallback, useContext, useMemo } from "react";
import {
  BsCalculator,
  BsFillFileEarmarkTextFill,
  BsFonts,
  BsListColumns,
  BsPuzzle,
  BsRegex,
  BsSearch,
  BsType,
} from "react-icons/bs";
import { useStatePersist } from "use-state-persist";
import { EnigmaStudioContext } from "../../context/EnigmaStudioContext";
import { recognize } from "tesseract.js";
import { useWorker } from "../../../../hooks/useWorker";
import { extractTextFromPDF } from "../../../../lib/file/extractTextFromPDF";
import { readFileAsText } from "../../../../lib/readFileAsText";
import {
  categorizedWordListFree,
  categorizedWordListFull,
} from "../../../../lib/wordlist/categorizedWordList";
import { Subtitle } from "../../common/Title";
import { AmidaGenerator } from "./WordGenerator/Converter/AmidaGenerator";
import { Calculator } from "./WordGenerator/Converter/Calculator";
import { EnigmaConverter } from "./WordGenerator/Converter/EnigmaConverter";
import { FontConverter } from "./WordGenerator/Converter/FontConverter";
import { ListConverter } from "./WordGenerator/Converter/ListConverter";
import { RegexConverter } from "./WordGenerator/Converter/RegexConverter";
import { SearchConverter } from "./WordGenerator/Converter/SearchConverter";
import { SomeConverter } from "./WordGenerator/Converter/SomeConverter";
import { SpleadSheetLoader } from "./WordGenerator/Converter/SpleadSheetLoader";
import { TextTypeConverter } from "./WordGenerator/Converter/TextTypeConverter";
import { TranslateConverter } from "./WordGenerator/Converter/TranslateConverter";

export const WordList: FC = () => {
  const ctx = useContext(EnigmaStudioContext);
  const plan = ctx.plan;
  const [pattern, setPattern] = useStatePersist<string>(
    "word-list-pattern",
    ""
  );
  const [patternHistory, setPatternHistory] = useStatePersist<string[]>(
    "word-list-pattern-history",
    []
  );
  const [patternHistoryIndex, setPatternHistoryIndex] = useStatePersist<number>(
    "word-list-pattern-history-index",
    -1
  );
  const worker = useWorker();

  const setConverted = useCallback(
    (text: string) => {
      console.log(patternHistory, patternHistoryIndex, text);
      if (patternHistory[patternHistoryIndex] === text) {
        return;
      }
      setPatternHistory((prev) => {
        let _patternHistoryIndex = patternHistoryIndex;
        if (prev[_patternHistoryIndex] !== pattern) {
          prev = prev.slice(0, _patternHistoryIndex + 1);
          prev.push(pattern);
          _patternHistoryIndex++;
        }
        if (prev[_patternHistoryIndex] !== text) {
          prev = prev.slice(0, _patternHistoryIndex + 1);
          prev.push(text);
        }
        setPatternHistoryIndex(prev.length - 1);
        return prev;
      });
      setPattern(text);
    },
    [
      patternHistory,
      patternHistoryIndex,
      setPatternHistory,
      setPatternHistoryIndex,
      pattern,
      setPattern,
    ]
  );

  const undo = useCallback(() => {
    setPattern(patternHistory[patternHistoryIndex - 1]);
    setPatternHistoryIndex((prev) => prev - 1);
  }, [patternHistory, patternHistoryIndex, setPattern, setPatternHistoryIndex]);

  const redo = useCallback(() => {
    if (patternHistoryIndex + 1 >= patternHistory.length) {
      return;
    }
    setPattern(patternHistory[patternHistoryIndex + 1]);
    setPatternHistoryIndex((prev) => prev + 1);
  }, [patternHistory, patternHistoryIndex, setPattern, setPatternHistoryIndex]);

  const lineCount = useMemo(() => {
    return pattern.split("\n").length;
  }, [pattern]);
  const textCount = useMemo(() => {
    return pattern.length;
  }, [pattern]);

  const loadCustom = (word?: string) => {
    const message = word ?? pattern;
    worker.loadCustom(message, (result: string[] | undefined) => {
      if (result?.sort().join(",") === ctx.enabledDictionary.sort().join(",")) {
        ctx.setLoaded(true);
      }
    });
  };

  const [category, setCategory] = useStatePersist<readonly string[]>(
    "word-list-category",
    []
  );

  const categorizedWordList =
    plan === "normal" ? categorizedWordListFree : categorizedWordListFull;

  // to flat map
  const wordList = Object.keys(categorizedWordList)
    .map((key) => categorizedWordList[key])
    .reduce((prev, current) => {
      return { ...prev, ...current };
    }, {});

  const [displayTextType, setDisplayTextType] = useStatePersist<boolean>(
    "word-list-display-text-type",
    false
  );
  const [displayTextTypeSub, setDisplayTextTypeSub] = useStatePersist<boolean>(
    "word-list-display-text-type-sub",
    true
  );

  const [displayTranslate, setDisplayTranslate] = useStatePersist<boolean>(
    "word-list-display-translate",
    false
  );

  const [displayListConvert, setDisplayListConvert] = useStatePersist<boolean>(
    "word-list-display-list-convert",
    false
  );
  const [displaySomeConvert, setDisplaySomeConvert] = useStatePersist<boolean>(
    "word-list-display-some-convert",
    false
  );
  const [displaySomeConvertSub, setDisplaySomeConvertSub] =
    useStatePersist<boolean>("word-list-display-some-convert-sub", true);

  const [displayMathematics, setDisplayMathematics] = useStatePersist<boolean>(
    "word-list-display-mathematics",
    false
  );
  const [displayRegex, setDisplayRegex] = useStatePersist<boolean>(
    "word-list-display-regex",
    false
  );
  const [displayFonts, setDisplayFonts] = useStatePersist<boolean>(
    "word-list-display-fonts",
    false
  );

  const [displaySearch, setDisplaySearch] = useStatePersist<boolean>(
    "word-list-display-search",
    false
  );
  const [displayFile, setDisplayFile] = useStatePersist<boolean>(
    "word-list-display-file",
    false
  );

  const [displayAmida, setDisplayAmida] = useStatePersist<boolean>(
    "word-list-display-amida",
    false
  );

  const [displayEnigma, setDisplayEnigma] = useStatePersist<boolean>(
    "word-list-display-enigma",
    false
  );

  const onChangeFile = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      if (files) {
        const file = files[0];
        if (file.name.endsWith(".pdf")) {
          extractTextFromPDF(file).then((text) => {
            setConverted(text);
          });
        } else if (
          [
            ".jpg",
            ".jpeg",
            ".png",
            ".gif",
            ".bmp",
            ".tiff",
            ".tif",
            ".webp",
          ].some((ext) => file.name.endsWith(ext))
        ) {
          // image file
          // OCR
          console.log("OCR");
          recognize(file).then((data) => {
            const text = data.data.text;
            setConverted(text);
          });
        } else {
          readFileAsText(file).then((text) => {
            setConverted(text);
          });
        }
      }
    },
    [setConverted]
  );

  return (
    <>
      <Box maxWidth={"100%"}>
        <Chip
          label={category.length === 0 ? "すべて開く" : "すべて閉じる"}
          onClick={() => {
            // toggle
            if (category.length === 0) {
              setCategory(Object.keys(categorizedWordList));
            } else {
              setCategory([]);
            }
          }}
          color={"info"}
          sx={{ m: 0.5 }}
        />
        {Object.keys(categorizedWordList).map((key) => (
          <Chip
            key={key}
            label={key}
            onClick={() => {
              // toggle
              setCategory((prev) => {
                const index = prev.indexOf(key);
                if (index === -1) {
                  return prev.concat(key);
                }
                return prev.filter((_, i) => i !== index);
              });
            }}
            color={category.indexOf(key) === -1 ? "default" : "primary"}
            sx={{ m: 0.5 }}
          />
        ))}
      </Box>
      <Box>
        <Box maxWidth={"100%"}>
          {Object.keys(categorizedWordList)
            .filter((key) => category.includes(key))
            .map((key1, i) => (
              <Box maxWidth={"100%"} key={"box-" + i}>
                <Subtitle>
                  {key1}　
                  {Object.keys(categorizedWordList[key1]).map((key, j) => (
                    <Button
                      key={"categorized-" + i + "-" + j}
                      onClick={() => {
                        setConverted(
                          pattern.trimEnd() === ""
                            ? wordList[key]
                            : pattern.trimEnd() + "\n" + wordList[key]
                        );
                      }}
                      variant="outlined"
                      sx={{ m: 0.5 }}
                    >
                      {key}
                    </Button>
                  ))}
                </Subtitle>
              </Box>
            ))}
        </Box>
      </Box>

      <Box>
        <Box maxWidth={"80%"}>
          <TextField
            label="テキストボックス"
            variant="outlined"
            sx={{
              width: "100%",
              mt: 2,
            }}
            multiline
            rows={15}
            value={pattern}
            onChange={(event) => {
              setPattern(event.target.value);
            }}
            inputProps={{
              style: {
                fontFamily: "'M PLUS 1 Code', monospace",
              },
            }}
          />
        </Box>
        <Grid>
          <Grid item>
            <Typography
              variant="body2"
              sx={{
                color: "#ab47bc",
                my: 0,
                mb: 0.5,
                py: 0,
              }}
            >
              {lineCount}行 {textCount}文字
            </Typography>
          </Grid>
          <Grid item>
            <Button
              sx={{ mt: 2, mr: 2 }}
              variant="contained"
              onClick={() => {
                setPattern("");
                setPatternHistory([]);
                setPatternHistoryIndex(-1);
              }}
              disabled={pattern.trimEnd() === "" && patternHistoryIndex <= 0}
            >
              全消去
            </Button>
            <Button
              sx={{ mt: 2, mr: 2 }}
              variant="contained"
              onClick={() => {
                undo();
              }}
              disabled={patternHistoryIndex <= 0}
            >
              &lt;&lt;
            </Button>
            <Button
              sx={{ mt: 2, mr: 2 }}
              variant="contained"
              onClick={() => {
                redo();
              }}
              disabled={patternHistoryIndex + 1 >= patternHistory.length}
            >
              &gt;&gt;
            </Button>
            <Button
              sx={{ mt: 2, mr: 2 }}
              variant="contained"
              onClick={() => {
                setConverted("");
              }}
              disabled={pattern.trimEnd() === ""}
            >
              新規
            </Button>
          </Grid>
        </Grid>

        <Grid sx={{ mt: 2 }}>
          <Grid item>
            <ToggleButton
              value="text-type"
              selected={displayTextType}
              size="medium"
              color="primary"
              onChange={() => {
                setDisplayTextType(!displayTextType);
              }}
              sx={{ mr: 1 }}
            >
              <BsType />
            </ToggleButton>
            <ToggleButton
              value="text-type"
              selected={displayFonts}
              size="medium"
              color="primary"
              onChange={() => {
                setDisplayFonts(!displayFonts);
              }}
              sx={{ mr: 1 }}
            >
              <BsFonts />
            </ToggleButton>
            <ToggleButton
              value="list"
              selected={displayListConvert}
              size="medium"
              color="primary"
              onChange={() => {
                setDisplayListConvert(!displayListConvert);
              }}
              sx={{ mr: 1 }}
            >
              <BsListColumns />
            </ToggleButton>
            <ToggleButton
              value="someConvert"
              selected={displaySomeConvert}
              size="medium"
              color="primary"
              onChange={() => {
                setDisplaySomeConvert(!displaySomeConvert);
              }}
              sx={{ mr: 1 }}
            >
              <BsPuzzle />
            </ToggleButton>
            <ToggleButton
              value="mathematics"
              selected={displayMathematics}
              size="medium"
              color="primary"
              onChange={() => {
                setDisplayMathematics(!displayMathematics);
              }}
              sx={{ mr: 1 }}
            >
              <BsCalculator />
            </ToggleButton>
            <ToggleButton
              value="regex"
              selected={displayRegex}
              size="medium"
              color="primary"
              onChange={() => {
                setDisplayRegex(!displayRegex);
              }}
              sx={{ mr: 1 }}
            >
              <BsRegex />
            </ToggleButton>
            <ToggleButton
              value="search"
              selected={displaySearch}
              size="medium"
              color="primary"
              onChange={() => {
                setDisplaySearch(!displaySearch);
              }}
              sx={{ mr: 1 }}
            >
              <BsSearch />
            </ToggleButton>
            <ToggleButton
              value="file"
              selected={displayFile}
              size="medium"
              color="primary"
              onChange={() => {
                setDisplayFile(!displayFile);
              }}
              sx={{ mr: 1 }}
            >
              <BsFillFileEarmarkTextFill />
            </ToggleButton>
          </Grid>
          {displayTextType && (
            <>
              <Grid item>
                <Chip
                  key={"文字種変換"}
                  label={"文字種変換"}
                  onClick={() => {
                    // toggle
                    setDisplayTextTypeSub(!displayTextTypeSub);
                  }}
                  color={displayTextTypeSub ? "primary" : "default"}
                  sx={{ mt: 2, mr: 2 }}
                />

                <Chip
                  key={"翻訳"}
                  label={"翻訳"}
                  onClick={() => {
                    // toggle
                    setDisplayTranslate(!displayTranslate);
                  }}
                  color={displayTranslate ? "primary" : "default"}
                  sx={{ mt: 2, mr: 2 }}
                />
              </Grid>
              {displayTextTypeSub && (
                <TextTypeConverter
                  pattern={pattern}
                  setConverted={setConverted}
                />
              )}
              {displayTranslate && (
                <TranslateConverter
                  pattern={pattern}
                  setConverted={setConverted}
                />
              )}
            </>
          )}
          {displayFonts && (
            <FontConverter pattern={pattern} setConverted={setConverted} />
          )}
          {displayListConvert && (
            <ListConverter pattern={pattern} setConverted={setConverted} />
          )}
          {displaySomeConvert && (
            <>
              <Grid item>
                <Chip
                  key={"各種変換"}
                  label={"各種変換"}
                  onClick={() => {
                    // toggle
                    setDisplaySomeConvertSub(!displaySomeConvertSub);
                  }}
                  color={displaySomeConvertSub ? "primary" : "default"}
                  sx={{ mt: 2, mr: 2 }}
                />
                <Chip
                  key={"あみだくじ"}
                  label={"あみだくじ"}
                  onClick={() => {
                    // toggle
                    setDisplayAmida(!displayAmida);
                  }}
                  color={displayAmida ? "primary" : "default"}
                  sx={{ mt: 2, mr: 2 }}
                  disabled={plan === "normal"}
                />
                <Chip
                  key={"エニグマ暗号"}
                  label={"エニグマ暗号"}
                  onClick={() => {
                    // toggle
                    setDisplayEnigma(!displayEnigma);
                  }}
                  color={displayEnigma ? "primary" : "default"}
                  sx={{ mt: 2, mr: 2 }}
                />
              </Grid>
              {displaySomeConvertSub && (
                <SomeConverter pattern={pattern} setConverted={setConverted} />
              )}
              {displayAmida && plan !== "normal" && (
                <Grid item>
                  <AmidaGenerator
                    pattern={pattern}
                    setConverted={setConverted}
                  />
                </Grid>
              )}
              {displayEnigma && (
                <Grid item>
                  <EnigmaConverter
                    pattern={pattern}
                    setConverted={setConverted}
                  />
                </Grid>
              )}
            </>
          )}
          {displayMathematics && (
            <Calculator pattern={pattern} setConverted={setConverted} />
          )}
          {displayRegex && (
            <RegexConverter pattern={pattern} setConverted={setConverted} />
          )}
          {displaySearch && (
            <SearchConverter pattern={pattern} setConverted={setConverted} />
          )}
          {displayFile && (
            <>
              <Grid item>
                <Button
                  sx={{ mt: 2, mr: 2 }}
                  variant="contained"
                  onClick={() => {
                    navigator.clipboard.writeText(pattern);
                  }}
                >
                  クリップボードにコピー
                </Button>
                <Button
                  variant="contained"
                  sx={{ mt: 2, mr: 2 }}
                  onClick={() => loadCustom(pattern)}
                >
                  カスタム辞書に設定
                </Button>
              </Grid>
              <Grid item sx={{ my: 2 }}>
                <input name="file" type="file" onChange={onChangeFile} />
              </Grid>
              {["pro", "pro+", "debug"].includes(plan) && (
                <SpleadSheetLoader
                  pattern={pattern}
                  setConverted={setConverted}
                />
              )}
            </>
          )}
        </Grid>
      </Box>
    </>
  );
};
