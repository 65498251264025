import { doc, onSnapshot, setDoc } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { useFirebaseConfig } from "../../../EniverseProvider";

type DocArg<T> = {
  path?: string | null;
  initialData?: T;
};

const useDoc = <T>({
  path,
  initialData
}: DocArg<T>): [T | undefined, (data: T) => void, boolean] => {
  const [data, setLocalData] = useState<T>(initialData as T);
  const [{ cloudFirestore }] = useFirebaseConfig();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!cloudFirestore) {
      return;
    }
    if (!path) {
      return;
    }
    const unsub = onSnapshot(doc(cloudFirestore, path), (docSnapshot) => {
      if (!docSnapshot.exists()) {
        setLoading(false);
        return;
      }
      setLoading(false);
      setLocalData({ ...docSnapshot.data() } as T);
    });
    return unsub;
  }, [cloudFirestore, path]);

  const setData = useCallback(
    (data: T) => {
      if (!cloudFirestore) {
        return;
      }
      if (!path) {
        return;
      }
      setDoc(doc(cloudFirestore, path), data as any);
    },
    [cloudFirestore, path]
  );

  return [data, setData, loading];
};

export { useDoc };
