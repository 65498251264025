import { workerStatus } from "../worker";
import { createRegex } from "./exRegexp";
import { progress, sendProgress } from "./generalSearchProgress";
import { processNestedFunctions } from "./patternFunctions";
export const regexReplace = async (workerContext, query, listDefinitionsTable, result) => {
    const pattern = (query.pattern ?? "") === "" ? "^(.*)$" : query.pattern;
    const reg = createRegex(pattern, listDefinitionsTable);
    if (query.matchOnly) {
        result = result.filter((e) => {
            return e.currentWord.match(reg);
        });
    }
    progress.get().queryProgress[progress.get().queryProgress.length - 1].totalWordCount += result.length;
    const temp = progress.get().queryProgress[progress.get().queryProgress.length - 1]
        .currentWordCount;
    const ret = [];
    let i = 0;
    for (const e of result) {
        ++i;
        if (workerStatus.abort) {
            break;
        }
        progress.get().queryProgress[progress.get().queryProgress.length - 1].currentWordCount = temp + i;
        if ((temp + i) % 100 === 0) {
            if (await sendProgress(workerContext)) {
                break;
            }
        }
        const replaced = e.currentWord.replaceAll(reg, query.replacement ?? "");
        ret.push(processNestedFunctions(replaced, listDefinitionsTable, e.trace).map((e2) => {
            // deep clone e
            const newe = JSON.parse(JSON.stringify(e));
            newe.currentWord = e2;
            return newe;
        }));
    }
    result = ret.flat();
    await sendProgress(workerContext);
    return result;
};
