import { frypanDictionary } from "../data/frypan";
import { dataSource } from "../dictionaryWorker";
import { workerStatus } from "../worker";
import { progress, sendProgress } from "./generalSearchProgress";
import { generalSearchFuncTable } from "./search/generalSearchFunctions";
import { getLastTrace } from "./trace";
const queryFunc = async (args) => {
    if (workerStatus.abort) {
        return args.result;
    }
    progress.get().queryProgress.push({
        totalWordCount: args.result.length,
        currentWordCount: 0,
        startTime: Date.now(),
    });
    await sendProgress(args.workerContext);
    let result = await generalSearchFuncTable[args.query.type](args);
    const resultList = new Map();
    result.forEach((e) => {
        const estimation = Math.max(e.currentEstimation ?? -Infinity, resultList.get(JSON.stringify(e.trace)) ?? -Infinity);
        resultList.set(JSON.stringify(e.trace), estimation);
    });
    result = Array.from(resultList).map(([e, v]) => {
        const ret = {
            trace: JSON.parse(e),
            currentWord: "",
        };
        ret.currentWord = getLastTrace(ret.trace);
        if (v !== -Infinity) {
            ret.currentEstimation = v;
        }
        return ret;
    });
    if (!workerStatus.abort) {
        const lastQueryProgress = progress.get().queryProgress[progress.get().queryProgress.length - 1];
        lastQueryProgress.currentWordCount = lastQueryProgress.totalWordCount;
        lastQueryProgress.endTime = Date.now();
    }
    await sendProgress(args.workerContext, true);
    return result;
};
export const onGeneralSearch = async (workerContext, input) => {
    let words = input.inputDictionary === "free"
        ? input.inputDictionaryFree.split("\n")
        : input.inputDictionary === "frypan"
            ? frypanDictionary
            : Array.isArray(input.inputDictionary)
                ? dataSource.getArray(input.inputDictionary)
                : dataSource.get(input.inputDictionary);
    if (!words) {
        return [];
    }
    progress.set({
        startTime: Date.now(),
        currentTime: Date.now(),
        queryProgress: [],
    });
    await sendProgress(workerContext);
    let result = words.map((word) => {
        if (input.estimation) {
            return {
                trace: [],
                currentWord: word,
                currentEstimation: input.estimation.initialValue,
            };
        }
        return {
            trace: [],
            currentWord: word,
        };
    });
    const listDefinitionsTable = input.listDefinitions.reduce((table, listDefinition) => {
        listDefinition.name.split(/\s*[\|/:]\s*/g).forEach((name) => {
            table[name] = listDefinition;
        });
        return table;
    }, {});
    result.forEach((e) => {
        e.trace.push(e.currentWord);
    });
    for (const query of input.queries) {
        if (!(query.enabled ?? true)) {
            continue;
        }
        if (query.type === "sequence") {
            for (const process of query.processes) {
                result = await queryFunc({
                    query: process,
                    input,
                    words,
                    listDefinitionsTable,
                    result,
                    workerContext,
                });
            }
        }
        else {
            result = await queryFunc({
                query,
                input,
                words,
                listDefinitionsTable,
                result,
                workerContext,
            });
        }
    }
    const ret = input.estimation
        ? result.map((e) => ({
            v: e.currentEstimation ?? 1,
            s: e.trace.join(","),
        }))
        : result.map((e) => ({
            s: e.trace.join(","),
        }));
    await sendProgress(workerContext, true);
    return { result: ret, progress: progress.get() };
};
