/* eslint-disable no-restricted-globals */
import { Combination, combination } from "js-combinatorics";
import { dataSource } from "./dictionaryWorker";
import { timeout } from "./lib/timeout";
import { dakuToSei, smallToLarge } from "../wordGenerator/kanaNormalize";
import { workerStatus } from "./worker";
import { splitGrapheme } from "../wordGenerator/splitGrapheme";
let beforeTime = 0;
let progress = {
    startTime: 0,
    currentTime: 0,
    foundCount: 0,
    searchCount: 0,
    hitCount: 0,
    searchingKeys: [],
    searchingWords: [],
};
const initProgress = () => {
    progress = {
        startTime: Date.now(),
        currentTime: Date.now(),
        foundCount: 0,
        searchCount: 0,
        hitCount: 0,
        searchingKeys: [],
        searchingWords: [],
    };
};
const sendProgress = async (forced) => {
    progress.currentTime = Date.now();
    if (forced || beforeTime + 50 < progress.currentTime) {
        self.postMessage({ progress, abort: workerStatus.abort });
        await timeout(0);
        beforeTime = progress.currentTime;
    }
    return workerStatus.abort;
};
// dict の初期化
const sortedLengthTable = {};
const tableMaxLength = 20;
const toOption = (word, option) => {
    if (option.ignoreSeiDaku) {
        word = dakuToSei(word);
    }
    if (option.ignoreSmall) {
        word = smallToLarge(word);
    }
    return word;
};
const toKey = (word, option) => {
    word = toOption(word, option);
    return splitGrapheme(word).sort().join("");
};
const createSortedTable = (dictionary, option) => {
    const words = dataSource[dictionary];
    if (!words) {
        return {};
    }
    const table = [
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
    ];
    for (const word of words) {
        if (word.length > tableMaxLength) {
            continue;
        }
        const key = toKey(word, option);
        table[key.length][key] = table?.[key.length]?.[key] ?? [];
        table[key.length][key].push(word);
    }
    return (sortedLengthTable[dictionary] = table);
};
let memo = {};
const removeCharacters = (word, sub) => {
    // 文字の出現回数を記録するマップを作成
    const charMap = {};
    for (const char of word) {
        if (charMap[char]) {
            charMap[char]++;
        }
        else {
            charMap[char] = 1;
        }
    }
    // wArrの各文字について、マップからその文字のカウントを減らす
    for (const char of sub) {
        charMap[char] = charMap[char] ?? 0;
        charMap[char]--;
        if (charMap[char] < 0) {
            return false;
        }
    }
    // マップを使って新しい文字列を構築
    const newWord = [];
    for (const char of word) {
        if (charMap[char] > 0) {
            newWord.push(char);
            charMap[char]--;
        }
    }
    return newWord.join("");
};
const recursiveAnagram = async (sortedLengthTable, word, //文字列
_maxLength, _initial, _minLength, ngTable, sortedNgListTable, sortedNgTable, minWordCount = 1, maxWordCount = 1000, depth = 0) => {
    const minLength = _minLength ?? 2;
    if (word.length <= minLength - 1) {
        return [];
    }
    if (maxWordCount < 1) {
        return [];
    }
    if (workerStatus.abort) {
        return [];
    }
    const maxLength = Math.min(_maxLength ?? tableMaxLength, word.length, tableMaxLength);
    if (word.length / maxLength > maxWordCount) {
        return [];
    }
    const initial = _initial ?? "";
    if (initial.length === word.length && initial > word) {
        return [];
    }
    const maxWordCountKey = maxWordCount > 900 ? 900 : maxWordCount;
    const minWordCountKey = minWordCount < 0 ? 0 : maxWordCount;
    const inputKey = `${word}:${maxLength}:${minLength}:${minWordCountKey}:${maxWordCountKey}`;
    ++progress.searchCount;
    if (memo[inputKey] !== undefined) {
        ++progress.hitCount;
        return memo[inputKey];
    }
    let ret = [];
    // 文字数が最大文字数より小さい場合、
    // または、文字数が最大文字数と同じで、かつ、初期文字列より大きい場合
    if (maxLength >= word.length) {
        // そのまま返す
        if (sortedLengthTable[word.length][word] != null && minWordCount <= 1) {
            ret = [].concat(sortedLengthTable[word.length][word]
                .filter((e) => !ngTable[e])
                .map((e) => [e]));
            progress.foundCount += ret.length;
        }
    }
    // 文字数でループ
    for (let l = Math.min(maxLength, word.length - minLength); l >= minLength; --l) {
        await sendProgress();
        if (workerStatus.abort) {
            break;
        }
        // 組み合わせを作成
        const combCount = combination(word.length, l);
        if (combCount < Object.keys(sortedLengthTable[l]).length) {
            const cmb = new Combination(word, l);
            const tbl = {};
            let i = 0;
            for (const a of cmb) {
                if (i % 1000 === 0) {
                    await sendProgress();
                }
                if (workerStatus.abort) {
                    break;
                }
                const w = a.join("");
                // 重複をチェック
                if (tbl[w]) {
                    ++i;
                    continue;
                }
                tbl[w] = true;
                if (initial.length == l && w < initial) {
                    ++i;
                    continue;
                }
                if (sortedNgTable[w]) {
                    ++i;
                    continue;
                }
                if (depth < 5) {
                    progress.searchingKeys[depth] = w;
                    progress.searchingWords[depth] =
                        sortedLengthTable[l][w]?.filter((e) => !sortedNgListTable[w]?.includes(e))[0] ?? "";
                }
                if (sortedLengthTable[w.length][w] !== undefined) {
                    // 取り除いた新しい文字列を作成
                    const newWord = removeCharacters(word, w);
                    if (!newWord) {
                        ++i;
                        continue;
                    }
                    const rec = await recursiveAnagram(sortedLengthTable, newWord, l, w, minLength, ngTable, sortedNgListTable, sortedNgTable, minWordCount - 1, maxWordCount - 1, depth + 1);
                    for (const w1 of sortedLengthTable[w.length][w]) {
                        if (ngTable[w1]) {
                            continue;
                        }
                        if (rec.length + 1 >= minWordCount) {
                            ret = ret.concat(rec.map((e) => [w1].concat(e)));
                        }
                    }
                    if (sortedLengthTable[w.length][w].length > 0) {
                        progress.foundCount +=
                            (sortedLengthTable[w.length][w].length -
                                1 -
                                (sortedNgListTable[w] ?? []).length) *
                                rec.length;
                    }
                }
                ++i;
            }
        }
        else {
            let i = 0;
            const keys = Object.keys(sortedLengthTable[l]).sort();
            for (const w of keys) {
                if (i % 1000 === 0) {
                    await sendProgress();
                }
                if (workerStatus.abort) {
                    break;
                }
                if (initial.length == l && w < initial) {
                    ++i;
                    continue;
                }
                if (sortedNgTable[w]) {
                    ++i;
                    continue;
                }
                // 取り除いた新しい文字列を作成
                const newWord = removeCharacters(word, w);
                if (!newWord) {
                    ++i;
                    continue;
                }
                if (depth < 5) {
                    progress.searchingKeys[depth] = w;
                    progress.searchingWords[depth] =
                        sortedLengthTable[l][w]?.filter((e) => !sortedNgListTable[w]?.includes(e))[0] ?? "";
                }
                const rec = await recursiveAnagram(sortedLengthTable, newWord, l, w, minLength, ngTable, sortedNgListTable, sortedNgTable, minWordCount - 1, maxWordCount - 1, depth + 1);
                for (const w1 of sortedLengthTable[w.length][w]) {
                    if (ngTable[w1]) {
                        continue;
                    }
                    if (rec.length + 1 >= minWordCount) {
                        ret = ret.concat(rec.map((e) => [w1].concat(e)));
                    }
                }
                if (sortedLengthTable[w.length][w].length > 0) {
                    progress.foundCount +=
                        (sortedLengthTable[w.length][w].length -
                            1 -
                            (sortedNgListTable[w] ?? []).length) *
                            rec.length;
                }
                ++i;
            }
        }
    }
    memo[inputKey] = ret;
    return ret;
};
export const searchMultiple = async (payload) => {
    const sortedLengthTable = createSortedTable(payload.dictionary, payload.option) ?? {};
    const minLength = payload.minimumLength === "" ? 2 : parseInt(payload.minimumLength);
    const unuseWord = toOption(payload.unuseWord, payload.option);
    const ngWords = unuseWord === "" ? [] : unuseWord.split(/[\s,、]/);
    const ngTable = ngWords.reduce((a, b) => {
        a[b] = true;
        return a;
    }, {});
    const sortedNgListTable = ngWords
        .map((e) => ({ key: toKey(e, payload.option), value: e }))
        .reduce((a, b) => {
        if (!a[b.key]) {
            a[b.key] = [];
        }
        a[b.key].push(b.value);
        return a;
    }, {});
    for (const key of Object.keys(sortedNgListTable)) {
        sortedNgListTable[key].sort();
    }
    const sortedNgTable = Object.entries(sortedNgListTable).reduce((a, [key, value]) => {
        if (sortedLengthTable[key.length][key].every((e, i) => e === value[i])) {
            a[key] = true;
        }
        return a;
    }, {});
    const minWordCount = payload.minimumWordCount === "" ? 1 : parseInt(payload.minimumWordCount);
    const maxWordCount = payload.maximumWordCount === "" ? 1000 : parseInt(payload.maximumWordCount);
    const hasPlus = payload.keyword.match(/\+\d+$/);
    const hasMinus = payload.keyword.match(/\-\d+$/);
    const num = parseInt((payload.keyword.match(/[\+\-](\d+)$/) ?? [])[1] ?? "0");
    if (hasPlus) {
        console.log("hasPlus");
        const keyword = payload.keyword.replace(/[\+\-]\d+$/g, "");
        let result = [];
        const useCharacters = [
            ...new Set(Object.values(sortedLengthTable).flatMap((e) => Object.keys(e).flatMap((e) => splitGrapheme(e)))),
        ].sort();
        initProgress();
        for (const c of useCharacters) {
            let remainWord = toKey(keyword + c, payload.option);
            let useWord = [];
            if (payload.useWord !== "") {
                useWord = payload.useWord.split(/[\s,、]+/);
                const useWordList = splitGrapheme(toKey(useWord.join(""), payload.option));
                remainWord = useWordList.reduce((a, b) => a.replace(b, ""), remainWord);
            }
            const maxLength = payload.maximumLength === ""
                ? Math.min(tableMaxLength, remainWord.length)
                : Math.min(tableMaxLength, remainWord.length, parseInt(payload.maximumLength));
            await sendProgress(true);
            memo = {};
            const ret = await recursiveAnagram(sortedLengthTable, remainWord, maxLength, "", minLength, ngTable, sortedNgListTable, sortedNgTable, minWordCount - useWord.length, maxWordCount - useWord.length, 0);
            result = result.concat(ret.map((e) => (useWord ? useWord.join(" ") + " " : "") +
                e.join(" ") +
                " (+" +
                c +
                ")"));
        }
        return result;
    }
    else if (hasMinus) {
        console.log("hasMinus");
        const keyword = payload.keyword.replace(/\s*[\+\-]\d+\s*$/g, "");
        let _result = [];
        initProgress();
        const cmb = new Combination(keyword, num);
        console.log(keyword, num);
        const tbl = {};
        for (const it of [...cmb]) {
            const key = it.sort().join("");
            if (tbl[key]) {
                continue;
            }
            tbl[key] = true;
            const _keyword = it.reduce((a, b) => a.replace(b, ""), keyword);
            let remainWord = toKey(_keyword, payload.option);
            let useWord = [];
            if (payload.useWord !== "") {
                useWord = payload.useWord.split(/[\s,、]+/);
                const useWordList = splitGrapheme(toKey(useWord.join(""), payload.option));
                remainWord = useWordList.reduce((a, b) => a.replace(b, ""), remainWord);
            }
            const maxLength = payload.maximumLength === ""
                ? Math.min(tableMaxLength, remainWord.length)
                : Math.min(tableMaxLength, remainWord.length, parseInt(payload.maximumLength));
            await sendProgress(true);
            memo = {};
            const ret = await recursiveAnagram(sortedLengthTable, remainWord, maxLength, "", minLength, ngTable, sortedNgListTable, sortedNgTable, minWordCount - useWord.length, maxWordCount - useWord.length, 0);
            _result = _result.concat(ret.map((word) => ({
                useWord: useWord ? useWord.join(" ") + " " : "",
                word,
                key,
            })));
        }
        return _result
            .sort((a, b) => {
            const len = a.word
                .map((e) => e.length)
                .reduce((a, b) => a + ("00" + b).slice(-2), "")
                .localeCompare(b.word
                .map((e) => e.length)
                .reduce((a, b) => a + ("00" + b).slice(-2), ""));
            if (len !== 0) {
                return -len;
            }
            return a.word.join("").localeCompare(b.word.join(""));
        })
            .map(({ useWord, word, key }) => useWord + word.join(" ") + " (-" + key + ")");
    }
    else {
        let remainWord = toKey(payload.keyword, payload.option);
        let useWord = [];
        if (payload.useWord !== "") {
            useWord = payload.useWord.split(/[\s,、]+/);
            const useWordList = splitGrapheme(toKey(useWord.join(""), payload.option));
            remainWord = useWordList.reduce((a, b) => a.replace(b, ""), remainWord);
        }
        const maxLength = payload.maximumLength === ""
            ? Math.min(tableMaxLength, remainWord.length)
            : Math.min(tableMaxLength, remainWord.length, parseInt(payload.maximumLength));
        initProgress();
        await sendProgress(true);
        memo = {};
        const ret = await recursiveAnagram(sortedLengthTable, remainWord, maxLength, "", minLength, ngTable, sortedNgListTable, sortedNgTable, minWordCount - useWord.length, maxWordCount - useWord.length, 0);
        return ret.map((e) => (useWord ? useWord.join(" ") + " " : "") + e.join(" "));
    }
};
