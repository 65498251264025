import { searchMultiple } from "./anagram";
import { searchChimata } from "./chimata";
import { parseEquation, solveCryptarithm } from "./cryptoarismetic";
import { onLoad, onLoadCustom, onLoadCustomArr, onUnload, } from "./dictionaryWorker";
import { onGeneralSearch } from "./generalSearch/generalSearch";
import { onNumCrossSearch } from "./numCrossSearch";
import { onNumToWordSearch, onRegexReplaceSearch, onRegexSearch, } from "./search";
import { onWordMatching } from "./wordMatching";
export const workerStatus = {
    abort: false,
};
export const postMessage = (message) => {
    self.postMessage(message);
};
self.onmessage = async (ev) => {
    const { type, payload } = ev.data;
    console.log(type, payload);
    switch (type) {
        case "load":
            onLoad(self, payload.dictionary, payload.hasAll);
            break;
        case "loadCustom":
            onLoadCustom(self, payload.text, payload.hasAll);
            break;
        case "loadCustomArr":
            onLoadCustomArr(self, payload.items, payload.hasAll);
            break;
        case "unload":
            onUnload(self, payload.dictionary, payload.hasAll);
            break;
        case "chimataSearch": {
            const { keyword, addSize, dictionary } = payload;
            const result = searchChimata(keyword, addSize, dictionary);
            postMessage({ result });
            break;
        }
        case "multipleAnagram": {
            workerStatus.abort = false;
            const result = await searchMultiple(payload);
            postMessage({ result });
            break;
        }
        case "cryptoArithmetic": {
            workerStatus.abort = false;
            const { input1, solutionCount } = payload;
            const replacedInput1 = input1
                .replaceAll("×", "*")
                .replaceAll("＊", "*")
                .replaceAll("÷", "/")
                .replaceAll("／", "/")
                .replaceAll("＋", "+")
                .replaceAll("－", "-")
                .replaceAll("＝", "=")
                .replaceAll("＜", "<")
                .replaceAll("＞", ">")
                .replaceAll("（", "(")
                .replaceAll("）", ")")
                .replaceAll("０", "0")
                .replaceAll("１", "1")
                .replaceAll("２", "2")
                .replaceAll("３", "3")
                .replaceAll("４", "4")
                .replaceAll("５", "5")
                .replaceAll("６", "6")
                .replaceAll("７", "7")
                .replaceAll("８", "8")
                .replaceAll("９", "9");
            const result = Object.values(await solveCryptarithm(replacedInput1.split("\n").map((line) => parseEquation(line)), solutionCount)).map((line) => `(${Object.entries(line)
                .sort((a, b) => (a[0] < b[0] ? -1 : 1))
                .map(([key]) => key)
                .join(",")}) = (${Object.entries(line)
                .sort((a, b) => (a[0] < b[0] ? -1 : 1))
                .map(([, value]) => value)
                .join(",")})`);
            postMessage({ result });
            break;
        }
        case "numCrossSearch": {
            const { keyword1, keyword2, keyword3, keyword4, range1, range2, range3, range3Option, dictionary, } = payload;
            const result = onNumCrossSearch(keyword1, keyword2, keyword3, keyword4, range1, range2, range3, range3Option, dictionary);
            postMessage(result);
            break;
        }
        case "regexSearch": {
            const { keyword, dictionary } = payload;
            const result = onRegexSearch(keyword, dictionary);
            postMessage(result);
            break;
        }
        case "regexReplaceSearch": {
            const { keywords, replaces, dictionary, dictionary2 } = payload;
            const result = onRegexReplaceSearch(keywords, replaces, dictionary, dictionary2);
            postMessage(result);
            break;
        }
        case "numToWordSearch": {
            const { value, pattern, dictionary } = payload;
            const result = onNumToWordSearch(value, pattern, dictionary);
            postMessage(result);
            break;
        }
        case "wordMatching": {
            const { keywords, wordLength, dictionary } = payload;
            const result = onWordMatching(keywords, wordLength, dictionary);
            postMessage(result);
            break;
        }
        case "generalSearch": {
            const { input } = payload;
            workerStatus.abort = false;
            const ret = await onGeneralSearch(self, input);
            postMessage(ret);
            break;
        }
        case "abort":
            console.log("abort");
            workerStatus.abort = true;
            break;
        default:
            break;
    }
};
