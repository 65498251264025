import { Divider, Grid, Stack } from "@mui/material";
import { ListResult, listAll, ref, uploadBytes } from "firebase/storage";
import { FC, useCallback, useEffect, useState } from "react";
import { useFirebaseConfig } from "../../EniverseProvider";
import { DragDropZone } from "../../imageUpload/DragDropZone";
import { ImageItem } from "../../imageUpload/ImageItem";
import { MdContainer } from "../../siteKit/uiKit/Container/Container";
import { CommonAppProp } from "./CommponAppProp";

export const ImageLibraryView: FC<CommonAppProp> = (props) => {
  const { currentNode, updateNode } = props;
  const [selectedFiles, setSelectedFiles] = useState<File[] | undefined>([]);
  const [storageList, setStorageList] = useState<ListResult | null>(null);
  const [{ cloudStorage }] = useFirebaseConfig();

  const handleSelectedFiles = useCallback(
    (files: File[] | undefined) => {
      setSelectedFiles(files);
    },
    [setSelectedFiles]
  );

  const getStorageList = useCallback(() => {
    const path = currentNode.data?.contentsId;
    if (!path) return;
    if (!cloudStorage) return;
    const storageRef = ref(cloudStorage, "" + path);
    listAll(storageRef).then((res) => {
      setStorageList(res);
    });
  }, []);

  useEffect(() => {
    getStorageList();
  }, [currentNode, getStorageList]);

  const handleUpload = useCallback(
    ({ success, failed }: { success: () => void; failed: () => void }) => {
      if (!selectedFiles) return;
      const path = currentNode.data?.contentsId;
      if (!path) return;
      if (!cloudStorage) return;
      const tasks: Promise<any>[] = [];
      selectedFiles.forEach((file) => {
        const storageRef = ref(cloudStorage, `${path}/${file.name}`);
        tasks.push(uploadBytes(storageRef, file));
      });
      Promise.all(tasks)
        .then(() => {
          success();
          getStorageList();
        })
        .catch((error) => {
          failed();
        });
    },
    [selectedFiles]
  );

  console.log(
    "ImageLibraryView",
    currentNode,
    updateNode,
    selectedFiles,
    handleSelectedFiles,
    handleUpload
  );

  return (
    <MdContainer>
      <Stack sx={{ my: 2 }}>
        <DragDropZone
          onSelectedFiles={handleSelectedFiles}
          handleUpload={handleUpload}
        />
        <Divider />
        <Grid container>
          {storageList?.items.map((item) => {
            return <ImageItem key={item.fullPath} imageRef={item} />;
          })}
        </Grid>
      </Stack>
    </MdContainer>
  );
};
