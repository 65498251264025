import { Breadcrumbs, Button, Typography } from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import HomeIcon from "@mui/icons-material/Home";
type Breadcrumb = {
  title: string;
  path: string;
};

export type CommonBreadcrumbsProps = {
  breadcrumbs?: Breadcrumb[];
};

export const CommonBreadcrumbs: FC<CommonBreadcrumbsProps> = ({
  breadcrumbs,
}) => {
  return (
    <>
      {breadcrumbs && (
        <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 1 }}>
          {breadcrumbs?.map(({ title, path }, index) => (
            <Typography color="text.primary">
              <Button
                variant="outlined"
                component={Link}
                to={path}
                sx={{ mx: 1 }}
              >
                {title}
              </Button>
            </Typography>
          ))}
        </Breadcrumbs>
      )}
    </>
  );
};
