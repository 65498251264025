import { GeneralSearchQuery } from "@langue-de-chat-llc/enigmastudio-algorithm";
import { FilterQueryBase } from "@langue-de-chat-llc/enigmastudio-algorithm/dist/param/generalSearchFilter";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MessageIcon from "@mui/icons-material/Message";
import SpeedIcon from "@mui/icons-material/Speed";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Chip,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import Grid from "@mui/system/Unstable_Grid";
import { FC, useState } from "react";
import { Draggable } from "../../../../../lib/react-smooth-dnd";
import { QueryTypeChip, queryTypeTable } from "./QueryTypeChip";
import { CandidateCharactersForm } from "./form/CandidateCharactersForm";
import { ConditionalTransformForm } from "./form/ConditionalTransformForm";
import { DictionaryMatchForm } from "./form/DictionaryMatchForm";
import { FishboneFilterForm } from "./form/FishBoneFilterForm";
import { IdenticalTraceFilterForm } from "./form/IdenticalTraceFilterForm";
import { KeyValueMatchForm } from "./form/KeyValueMatchForm";
import { LengthForm } from "./form/LengthForm";
import { ParallelFilterForm } from "./form/ParallelFilterForm";
import { RegexFilterForm } from "./form/RegexFilterForm";
import { RegexMatchCompareForm } from "./form/RegexMatchCompareForm";
import { RegexReplaceForm } from "./form/RegexReplaceForm";
import { SequenceForm } from "./form/SequenceForm";
import { TraceGroupingForm } from "./form/TraceGroupingForm";
import { AnagramDictionaryMatchForm } from "./form/AnagramDictionaryMatchForm";
import { ParallelTransformForm } from "./form/ParallelTransformForm";

type QueryFormProps = {
  index: number;
  query: GeneralSearchQuery;
  updateQuery: (index: number, field: string, value: any) => void;
  deleteQueryField: (index: number, field: string) => void;
  removeQuery: (index: number) => void;
  displayTraceNumber: boolean;
  queries: GeneralSearchQuery[];
  sequencialQueries: GeneralSearchQuery[];
  traceCounter: number[];
  setQueries: (queries: GeneralSearchQuery[]) => void;
  setQuery: (query: string) => void;
  displayTooltip: boolean;
  isDemo: boolean;
  onSelect: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    contentIndex: number
  ) => void;
  setHoveredContentsIndex: (index: number) => void;
  hoveredContentsIndex: number;
};

export const QueryForm: FC<QueryFormProps> = ({
  index,
  query,
  updateQuery,
  deleteQueryField,
  removeQuery,
  displayTraceNumber,
  queries,
  sequencialQueries,
  traceCounter,
  setQueries,
  setQuery,
  displayTooltip,
  isDemo,
  onSelect,
  setHoveredContentsIndex,
}) => {
  const [displayMessageEdit, setDisplayMessageEdit] = useState(false);
  const isEstimation = !!(query as FilterQueryBase).unmatchEstimationOperator;
  const enableEstimation =
    (queryTypeTable[query.type as string] &&
      queryTypeTable[query.type as string].category === "filter") ||
    query.type === "parallelTransform";

  return (
    <>
      <Draggable>
        {displayTraceNumber &&
          traceCounter[index] !== traceCounter[index - 1] && (
            <Typography
              variant="body2"
              sx={{
                color: "#ab47bc",
                my: 0,
                mb: 0.5,
                py: 0,
              }}
            >
              トレース: [{traceCounter[index]}]
            </Typography>
          )}
        <Box
          key={index}
          mb={0.5}
          sx={{
            p: 1,
            backgroundColor: "palette.background.paper",
            opacity: query?.enabled === false ? 0.4 : 1,
          }}
          border={1}
          borderColor="#ccc"
          className="edit-box"
        >
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              my: 0,
              py: 0,
              cursor: "move",
            }}
            className="drag-handle"
            onMouseDown={(e) => {
              onSelect(e, index);
            }}
            onMouseEnter={() => {
              setHoveredContentsIndex(index);
            }}
            onMouseLeave={() => {
              setHoveredContentsIndex(-1);
            }}
          >
            <DragIndicatorIcon
              sx={{
                position: "relative",
                mx: "auto",
                transform: "rotate(90deg)",
              }}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <IconButton
              edge="start"
              size="small"
              onClick={() => {
                // toggle enabled
                const updatedQueries = [...queries];
                updatedQueries[index].enabled =
                  updatedQueries[index].enabled === undefined ||
                  updatedQueries[index].enabled === true
                    ? false
                    : true;
                setQueries(updatedQueries);
                setQuery(JSON.stringify(updatedQueries));
              }}
            >
              <VisibilityIcon fontSize="inherit" />
            </IconButton>

            <IconButton
              edge="start"
              size="small"
              disabled={index === 0 || isDemo}
              onClick={() => {
                const updatedQueries = [...queries];
                const tmp = { ...updatedQueries[index] };
                updatedQueries[index] = updatedQueries[index - 1];
                updatedQueries[index - 1] = tmp;
                setQueries(updatedQueries);
                setQuery(JSON.stringify(updatedQueries));
              }}
            >
              <KeyboardArrowUpIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              edge="start"
              size="small"
              onClick={() => {}}
              disabled={index === queries.length - 1 || isDemo}
            >
              <KeyboardArrowDownIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              edge="start"
              size="small"
              onClick={() => {
                const updatedQueries = [...queries];
                updatedQueries.splice(index + 1, 0, { ...query });
                setQueries(updatedQueries);
                setQuery(JSON.stringify(updatedQueries));
              }}
            >
              <ContentCopyIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              edge="start"
              size="small"
              onClick={() => {
                removeQuery(index);
              }}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              edge="start"
              size="small"
              color={displayMessageEdit ? "primary" : "default"}
              onClick={() => {
                setDisplayMessageEdit(!displayMessageEdit);
              }}
            >
              <MessageIcon fontSize="inherit" />
            </IconButton>
          </Box>
          <>
            {displayMessageEdit ? (
              <TextField
                fullWidth
                multiline
                label="コメント"
                value={query?.comment ?? ""}
                onChange={(e) => {
                  updateQuery(index, "comment", e.target.value);
                }}
                size="small"
                sx={{
                  my: 0,
                  mb: 0.5,
                  py: 0,
                  fontSize: "0.8rem",
                }}
              />
            ) : (
              query?.comment && (
                <Typography
                  variant="body2"
                  sx={{
                    color: "#444",
                    my: 0,
                    mb: 0.5,
                    py: 0,
                  }}
                >
                  {query.comment.split("\n").map((line, i) => (
                    <span key={i}>
                      {line}
                      <br />
                    </span>
                  ))}
                </Typography>
              )
            )}
          </>
          <Box
            sx={{
              display: "inline-block",
              width: "100%",
              mr: 1,
              mb: 2,
            }}
          >
            <QueryTypeChip query={query} isDemo={isDemo} />
            {enableEstimation && (
              <Chip
                icon={<SpeedIcon />}
                label="評価"
                variant={
                  (query as FilterQueryBase).unmatchEstimationOperator
                    ? "filled"
                    : "outlined"
                }
                color={
                  (query as FilterQueryBase).unmatchEstimationOperator
                    ? "error"
                    : "default"
                }
                onClick={() => {
                  if (!(query as FilterQueryBase).unmatchEstimationOperator) {
                    updateQuery(index, "matchEstimationOperator", "multiply");
                    updateQuery(index, "matchEstimationValue", "1.0");
                    updateQuery(index, "unmatchEstimationOperator", "multiply");
                    updateQuery(index, "unmatchEstimationValue", "0");
                  } else {
                    updateQuery(index, "matchEstimationOperator", undefined);
                    updateQuery(index, "matchEstimationValue", undefined);
                    updateQuery(index, "unmatchEstimationOperator", undefined);
                    updateQuery(index, "unmatchEstimationValue", undefined);
                  }
                }}
                sx={{ ml: 1 }}
              />
            )}
          </Box>
          {query?.type === "regex" && (
            <RegexFilterForm
              index={index}
              query={query}
              updateQuery={updateQuery}
              deleteQueryField={deleteQueryField}
              isDemo={isDemo}
            />
          )}
          {query?.type === "dictionaryMatch" && (
            <DictionaryMatchForm
              index={index}
              query={query}
              updateQuery={updateQuery}
              deleteQueryField={deleteQueryField}
              isDemo={isDemo}
            />
          )}
          {query?.type === "anagramDictionaryMatch" && (
            <AnagramDictionaryMatchForm
              index={index}
              query={query}
              updateQuery={updateQuery}
              deleteQueryField={deleteQueryField}
              isDemo={isDemo}
            />
          )}
          {query?.type === "keyValueMatch" && (
            <KeyValueMatchForm
              index={index}
              query={query}
              updateQuery={updateQuery}
              deleteQueryField={deleteQueryField}
              isDemo={isDemo}
            />
          )}
          {query?.type === "traceGrouping" && (
            <TraceGroupingForm
              index={index}
              query={query}
              updateQuery={updateQuery}
              deleteQueryField={deleteQueryField}
              isDemo={isDemo}
              traceCount={traceCounter[index]}
            />
          )}
          {query?.type === "identicalTrace" && (
            <IdenticalTraceFilterForm
              index={index}
              query={query}
              updateQuery={updateQuery}
              deleteQueryField={deleteQueryField}
              isDemo={isDemo}
            />
          )}
          {query?.type === "length" && (
            <LengthForm
              index={index}
              query={query}
              updateQuery={updateQuery}
              deleteQueryField={deleteQueryField}
              isDemo={isDemo}
            />
          )}
          {query?.type === "candidateCharacters" && (
            <CandidateCharactersForm
              index={index}
              query={query}
              updateQuery={updateQuery}
              deleteQueryField={deleteQueryField}
              isDemo={isDemo}
            />
          )}
          {query?.type === "regexMatchCompare" && (
            <RegexMatchCompareForm
              index={index}
              query={query}
              updateQuery={updateQuery}
              deleteQueryField={deleteQueryField}
              displayTraceNumber={displayTraceNumber}
              queries={sequencialQueries}
              isDemo={isDemo}
            />
          )}
          {query?.type === "regexReplace" && (
            <RegexReplaceForm
              index={index}
              query={query}
              updateQuery={updateQuery}
              deleteQueryField={deleteQueryField}
              isDemo={isDemo}
            />
          )}
          {query?.type === "parallelTransform" && (
            <ParallelTransformForm
              index={index}
              query={query}
              updateQuery={updateQuery}
              deleteQueryField={deleteQueryField}
              isDemo={isDemo}
              isEstimation={isEstimation}
            />
          )}
          {query?.type === "conditionalTransform" && (
            <ConditionalTransformForm
              index={index}
              query={query}
              updateQuery={updateQuery}
              isDemo={isDemo}
            />
          )}
          {query?.type === "fishBone" && (
            <FishboneFilterForm
              index={index}
              query={query}
              updateQuery={updateQuery}
              deleteQueryField={deleteQueryField}
              isDemo={isDemo}
            />
          )}
          {query?.type === "sequence" && (
            <SequenceForm
              index={index}
              query={query}
              sequencialQueries={sequencialQueries}
              displayTraceNumber={displayTraceNumber}
              updateQuery={updateQuery}
              deleteQueryField={deleteQueryField}
              onDelete={() => removeQuery(index)}
              onClick={() => {
                // toggle enabled
                const updatedQueries = [...queries];
                updatedQueries[index].enabled =
                  updatedQueries[index].enabled === undefined ||
                  updatedQueries[index].enabled === true
                    ? false
                    : true;
                setQueries(updatedQueries);
                setQuery(JSON.stringify(updatedQueries));
              }}
              displayTooltip={displayTooltip}
              isDemo={isDemo}
            />
          )}
          {query?.type === "parallelFilter" && (
            <ParallelFilterForm
              index={index}
              query={query}
              updateQuery={updateQuery}
              deleteQueryField={deleteQueryField}
              isDemo={isDemo}
              isEstimation={isEstimation}
            />
          )}
          {isEstimation && (
            <Grid container spacing={2}>
              {!queryTypeTable[query.type as string].tags.includes(
                "parallel"
              ) && (
                <Grid xs={12} md={6}>
                  <Box
                    sx={{
                      mt: 1,
                      ml: 0,
                      mb: 0,
                      textAlign: "left",
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ToggleButtonGroup
                      color="primary"
                      exclusive
                      value={
                        (query as FilterQueryBase)?.matchEstimationOperator ??
                        "multiply"
                      }
                      aria-label="Operator"
                    >
                      <ToggleButton
                        value="add"
                        size="small"
                        onClick={() => {
                          updateQuery(index, "matchEstimationOperator", "add");
                        }}
                      >
                        <AddIcon />
                      </ToggleButton>
                      <ToggleButton
                        value="multiply"
                        size="small"
                        onClick={() => {
                          updateQuery(
                            index,
                            "matchEstimationOperator",
                            "multiply"
                          );
                        }}
                      >
                        <CloseIcon />
                      </ToggleButton>
                    </ToggleButtonGroup>

                    <TextField
                      label="マッチ時評価値"
                      value={
                        (query as FilterQueryBase)?.matchEstimationValue ?? "1"
                      }
                      onChange={(e) => {
                        updateQuery(
                          index,
                          "matchEstimationValue",
                          e.target.value
                        );
                      }}
                      inputProps={{
                        inputMode: "decimal",
                        type: "number",
                        step: "0.1",
                      }}
                      size="small"
                      sx={{
                        ml: 1,
                        my: 0,
                        mb: 0.5,
                        py: 0,
                        fontSize: "0.8rem",
                      }}
                      disabled={isDemo}
                    />
                  </Box>
                </Grid>
              )}
              <Grid xs={12} sm={6}>
                <Box
                  sx={{
                    mt: 1,
                    ml: 0,
                    mb: 0,
                    textAlign: "left",
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ToggleButtonGroup
                    color="primary"
                    exclusive
                    value={
                      (query as FilterQueryBase)?.unmatchEstimationOperator ??
                      "multiply"
                    }
                    aria-label="Operator"
                  >
                    <ToggleButton
                      value="add"
                      size="small"
                      onClick={() => {
                        updateQuery(index, "unmatchEstimationOperator", "add");
                      }}
                    >
                      <AddIcon />
                    </ToggleButton>
                    <ToggleButton
                      value="multiply"
                      size="small"
                      onClick={() => {
                        updateQuery(
                          index,
                          "unmatchEstimationOperator",
                          "multiply"
                        );
                      }}
                    >
                      <CloseIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>

                  <TextField
                    label="非マッチ時評価値"
                    value={
                      (query as FilterQueryBase)?.unmatchEstimationValue ?? "0"
                    }
                    inputProps={{
                      inputMode: "decimal",
                      type: "number",
                      step: "0.1",
                    }}
                    onChange={(e) => {
                      updateQuery(
                        index,
                        "unmatchEstimationValue",
                        e.target.value
                      );
                    }}
                    size="small"
                    sx={{
                      ml: 1,
                      my: 0,
                      mb: 0.5,
                      py: 0,
                      fontSize: "0.8rem",
                    }}
                    disabled={isDemo}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </Draggable>
    </>
  );
};
