class DataSource {
    all = null;
    common = null;
    buta = null;
    pref = null;
    neologd_user = null;
    naist_jdic = null;
    unidic = null;
    english = null;
    cefr = null;
    // poke: string[] | null = null;
    common_hepburn = null;
    nicoime = null;
    nico_intersection_pixiv = null;
    hatena = null;
    // idolmaster: string[] | null = null;
    // kaikei: string[] | null = null;
    // mildic: string[] | null = null;
    // gundam: string[] | null = null;
    // dqdic: string[] | null = null;
    food_illust = null;
    animal_koto = null;
    juku_skk_l = null;
    jukugo_on_wikipedia = null;
    illust1 = null;
    illust1_2 = null;
    illust1_3 = null;
    illust1_4 = null;
    illust1_5 = null;
    custom = null;
    get(key) {
        return this[key];
    }
    getArray(key) {
        return key.reduce((acc, cur) => {
            return acc.concat(this[cur] ?? []);
        }, []);
    }
    getAll() {
        return this.all;
    }
    set(key, value) {
        this[key] = value;
    }
    delete(key) {
        this[key] = null;
    }
}
export const dataSource = new DataSource();
const onAllLoad = () => {
    dataSource.set("all", [
        ...new Set((dataSource.neologd_user ?? [])
            .concat(dataSource.naist_jdic ?? [])
            .concat(dataSource.unidic ?? [])
            .concat(dataSource.buta ?? [])
            .concat(dataSource.common ?? [])
            .concat(dataSource.english ?? [])
            .concat(dataSource.cefr ?? [])
            .concat(dataSource.common_hepburn ?? [])
            .concat(dataSource.pref ?? [])
            // .concat(dataSource.poke ?? [])
            .concat(dataSource.nicoime ?? [])
            .concat(dataSource.nico_intersection_pixiv ?? [])
            .concat(dataSource.hatena ?? [])
            // .concat(dataSource.idolmaster ?? [])
            // .concat(dataSource.kaikei ?? [])
            // .concat(dataSource.mildic ?? [])
            // .concat(dataSource.gundam ?? [])
            // .concat(dataSource.dqdic ?? [])
            .concat(dataSource.food_illust ?? [])
            .concat(dataSource.animal_koto ?? [])
            .concat(dataSource.juku_skk_l ?? [])
            .concat(dataSource.jukugo_on_wikipedia ?? [])
            .concat(dataSource.illust1 ?? [])
            .concat(dataSource.illust1_2 ?? [])
            .concat(dataSource.illust1_3 ?? [])
            .concat(dataSource.illust1_4 ?? [])
            .concat(dataSource.illust1_5 ?? [])
            .concat(dataSource.custom ?? [])),
    ].sort());
};
export const onLoad = (self, key, hasAll) => {
    if (key === "all") {
        onAllLoad();
        self.postMessage(Object.keys(dataSource).filter((key) => dataSource[key] !== null));
        return;
    }
    const reader = new FileReader();
    console.log("load", key);
    reader.onload = function () {
        const text = reader.result;
        dataSource.set(key, [
            ...new Set(text
                .split("\n")
                .map((word) => word.split(/[\s,]/)[0])
                .filter((word) => word.length > 0 &&
                word.charAt(0) !== "#" &&
                word.charAt(0) !== ";" &&
                word.charAt(0) !== "!")),
        ].sort());
        if (hasAll) {
            onAllLoad();
        }
        self.postMessage(Object.keys(dataSource).filter((key) => dataSource.get(key) !== null));
        console.log(dataSource);
    };
    fetch(process.env.PUBLIC_URL + "/dictionary/" + key + ".txt")
        .then((response) => {
        return response.blob();
    })
        .then((blob) => {
        reader.readAsText(blob);
    });
};
export const onLoadCustom = (self, text, hasAll) => {
    const words = [
        ...new Set(text
            .split("\n")
            .map((word) => word.split(/[\s,]/)[0])
            .filter((word) => word.length > 0 &&
            word.charAt(0) !== "#" &&
            word.charAt(0) !== ";" &&
            word.charAt(0) !== "!")),
    ].sort();
    dataSource.set("custom", words);
    if (hasAll) {
        onAllLoad();
    }
    self.postMessage(Object.keys(dataSource.getAll() ?? {}).filter((key) => dataSource.get(key) !== null));
};
export const onLoadCustomArr = (self, items, hasAll) => {
    const words = [
        ...new Set(items
            .map((word) => word.split(/[\s]/)[0])
            .filter((word) => word.length > 0 &&
            word.charAt(0) !== "#" &&
            word.charAt(0) !== ";" &&
            word.charAt(0) !== "!")),
    ].sort();
    dataSource.set("custom", words);
    if (hasAll) {
        onAllLoad();
    }
    self.postMessage(Object.keys(dataSource.getAll() ?? {}).filter((key) => dataSource[key] !== null));
};
export const onUnload = (self, key, hasAll) => {
    dataSource.delete(key);
    if (hasAll) {
        onAllLoad();
    }
};
