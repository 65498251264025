import { splitGrapheme } from "../wordGenerator/splitGrapheme";
import { numCrossScore } from "../param/numCrossScore";
import { dataSource } from "./dictionaryWorker";
export const onNumCrossSearch = (keyword1, keyword2, keyword3, keyword4, range1, range2, range3, range3Option, dictionary) => {
    const words = dataSource[dictionary];
    if (!words) {
        return [];
    }
    console.log(keyword1, keyword2, keyword3, keyword4, range1, range2, range3, range3Option);
    const reg1 = new RegExp(keyword1);
    const reg2 = new RegExp(`[${keyword2}]`);
    const not_reg2 = new RegExp(`[^${keyword2}]`, "g");
    const reg3 = new RegExp(keyword3.split("").sort().join(".*"));
    const reg4 = new RegExp(`[${keyword4}]`);
    const result = words.filter((word) => {
        if (!word.match(reg1)) {
            return false;
        }
        if (word.length < range1[0] || word.length > range1[1]) {
            return false;
        }
        if (keyword2.length > 0) {
            const containLength = word.split(reg2).length - 1;
            if (containLength < range2[0] || containLength > range2[1]) {
                return false;
            }
            const unContainLength = range3Option === "char_count"
                ? word.length - containLength
                : new Set(word.match(not_reg2) ?? []).size;
            if (unContainLength < range3[0] || unContainLength > range3[1]) {
                return false;
            }
        }
        if (keyword3 !== "" && !word.split("").sort().join("").match(reg3)) {
            return false;
        }
        const notContainLength = word.split(reg4).length - 1;
        if (notContainLength > 0) {
            return false;
        }
        return true;
    });
    const score = (word) => {
        return (splitGrapheme(word).reduce((a, b) => {
            return a + (numCrossScore[b] ?? 0);
        }, 0) / word.length);
    };
    return result
        .map((word) => [word, score(word)])
        .sort((a, b) => b[1] - a[1])
        .map(([word]) => word);
};
