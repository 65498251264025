import { frypanDictionary } from "../../data/frypan";
import { dataSource } from "../../dictionaryWorker";
const sortedTable = {};
const dictionaryTable = {};
export const createSortedTable = (dictionary) => {
    const words = dictionary.includes("frypan")
        ? frypanDictionary
        : dataSource.getArray(dictionary);
    if (!words) {
        return {};
    }
    const key = dictionary.sort().join(",");
    if (sortedTable[key]) {
        return sortedTable[key];
    }
    const table = {};
    for (const word of words) {
        const key = word.split("").sort().join("");
        table[key] = table[key] ?? [];
        table[key].push(word);
    }
    return (sortedTable[key] = table);
};
export const createDictionaryTable = (dictionary) => {
    const key = dictionary.sort().join(",");
    if (dictionaryTable[key]) {
        return dictionaryTable[key];
    }
    const words = dictionary.includes("frypan")
        ? frypanDictionary
        : dataSource.getArray(dictionary);
    if (!words) {
        return {};
    }
    const table = {};
    for (const word of words) {
        table[word] = true;
    }
    return (dictionaryTable[key] = table);
};
