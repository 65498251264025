import { dataSource } from "./dictionaryWorker";
import { wordMatching } from "./lib/bipartiteMatching";
export const onWordMatching = (keywords, wordLength, dictionary) => {
    const words = dataSource[dictionary];
    if (!words) {
        return [];
    }
    // キーワードをコンマで分割し、配列に変換します。
    const keywordArr = keywords
        .replace(/\s+/g, ",")
        .split(",")
        .filter((e) => e !== "");
    const result = wordMatching(words, keywordArr, wordLength)
        .map((e) => e.word)
        .flat();
    // 結果をソートします。
    result.sort((a, b) => a.length !== b.length ? a.length - b.length : a < b ? -1 : 1);
    return result;
};
