import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import { NewDictionarySelector } from "../../../../commonStudio/NewDictionarySelector";
import { WordList } from "../../../Tools/WordList";

type GeneralSeachDictionarySelectorProps = {
  inputTabValue: string;
  setInputTabValue: (value: string) => void;
  dictionaries: string[];
  setDictionaries: (dictionaries: string[]) => void;
};

export const GeneralSeachDictionarySelector: FC<
  GeneralSeachDictionarySelectorProps
> = ({ inputTabValue, setInputTabValue, dictionaries, setDictionaries }) => {
  // Input Tab
  const handleInputTabChange = (
    _e: React.MouseEvent<HTMLElement, MouseEvent>,
    newValue: string
  ) => {
    setInputTabValue(newValue);
  };

  return (
    <>
      <Box sx={{ mt: 4 }}>
        <ToggleButtonGroup
          color="primary"
          value={inputTabValue}
          exclusive
          onChange={handleInputTabChange}
        >
          <ToggleButton value="dictionary">入力辞書選択</ToggleButton>
          <ToggleButton value="free">自由入力</ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {inputTabValue === "dictionary" && (
        <NewDictionarySelector
          selectedDictionaries={dictionaries}
          setSelectedDictionaries={setDictionaries}
          displayLabel={false}
        />
      )}
      <Box
        sx={{ pt: 1, pb: 2, mb: 3, borderBottom: 1, borderColor: "divider" }}
      >
        {inputTabValue === "free" && <WordList />}
      </Box>
    </>
  );
};
