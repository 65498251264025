import { splitGrapheme } from "../wordGenerator/splitGrapheme";
import { dataSource } from "./dictionaryWorker";
export const onRegexSearch = (keyword, dictionary) => {
    const words = dataSource[dictionary];
    if (!words) {
        return [];
    }
    const reg = new RegExp(keyword);
    const result = words.filter((word) => {
        return word.match(reg);
    });
    return result;
};
export const onNumToWordSearch = (value, pattern, dictionary) => {
    const words = dataSource[dictionary];
    if (!words) {
        return [];
    }
    const patterns = pattern.split("\n");
    const result = words.filter((word) => {
        const ind = splitGrapheme(word)
            .map((c) => {
            const v = Math.min(100000, ...patterns.map((line) => line.indexOf(c)).filter((e) => e !== -1));
            return v < 100000 ? v : 0;
        })
            .reduce((a, b) => a + b);
        return ind === value;
    });
    return result;
};
export const onRegexReplaceSearch = (keywords, replaces, dictionary, dictionary2) => {
    const words = dataSource[dictionary];
    const words2 = dataSource[dictionary2];
    if (!words) {
        return [];
    }
    if (!words2) {
        return [];
    }
    const dict = {};
    words2.reduce((dict, key) => {
        dict[key] = true;
        return dict;
    }, dict);
    const regs = keywords.map((keyword) => new RegExp(keyword, "gu"));
    const result = words
        .map((word) => {
        let flag = true;
        let replaced = word;
        regs.forEach((reg, i) => {
            if (!flag) {
                return;
            }
            if (!replaced.match(reg)) {
                flag = false;
                return;
            }
            replaced = replaced.replaceAll(reg, replaces[i]);
        });
        if (flag && dict[replaced]) {
            return `${word} -> ${replaced}`;
        }
        return false;
    })
        .filter((line) => line);
    return result;
};
